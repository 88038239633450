.app-header {
    position: fixed;
    top: 0;
    left: 243px; /* 👈 Match your .nav-bar width */
    right: 0;
    height: 50px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #b3cacc;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    z-index: 1000;
}

/* Header content layout */
.header-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

/* Left side (home button on small screens) */
.header-left {
    display: flex;
    justify-content: flex-start;
}

/* Right side (notifications, settings, profile) */
.header-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* Divider after Home Icon */
.home-icon::after {
    content: '|';
    margin-left: 8px;
    color: #b3cacc;
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
}

/* Make sure the home button only appears on smaller screens */
@media (max-width: 600px) {
    .header-left {
        display: flex;
        margin-right: auto;
    }

    .header-right {
        flex-grow: 1;
    }

    .app-header {
    width: 100%;
    padding: 0 5px 0 0;
    }
}

.header-avatar {
    margin-right: 10px;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.header-icon {
    margin-right: 15px;
    color: #4A4A4A;
}

.create-paper-button {
    font-weight: bold;
    font-size: 14px;
    text-transform: none;
}

.paper-options-container {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  width: 100%;
}

.paper-options {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  background-color: transparent;
}

.paper-options button {
  background-color: #5F9EA0;
  color: white;
  border-radius: 50px;
  padding: 7px 15px;
  width: clamp(90px, 20vw, 120px);
  font-size: clamp(6px, 2vw, 10px);
  transition: width 0.3s ease-in-out, font-size 0.3s ease-in-out; /* Smooth transition for size */
}

.paper-options button:hover {
  background-color: #498292; /* Darker shade of #5F9EA0 */
  color: white; /* Ensure text stays white */
}

/* Additional media query to ensure proper shrinking on small screens */
@media screen and (max-width: 700px) {
  .paper-options button {
      padding: 2px 8px;
  }
}

